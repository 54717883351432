import Vue from 'vue'
import Router from 'vue-router'

const Quote = () => import('@/components/Quote.vue')
const NotFound = () => import('@/components/NotFound.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  // mode: 'hash', // https://router.vuejs.org/api/#mode
  base: '/',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/', component: Quote},
    { path: '/quote/:id', component: Quote},
    { path: '*', component: NotFound }
  ]
})
